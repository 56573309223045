import React from "react"
import loadable from "@loadable/component"
import Layout from "../components/Layout"
import { Helmet } from "react-helmet"
import Head from "../components/utils/Head"

// Load Blog component lazily
const Blog = loadable(() => import("../components/Blog"), {
  fallback: <div className="loading-fallback">Loading Blog...</div>,
})

export default function BlogPage() {
  return (
    <Layout>
      <Helmet>
        <link rel="canonical" href="https://codefulcrum.com/blogs" />
      </Helmet>
      <Head
        metaTitle="Visit Our Blog for Tips on Custom Development - CodeFulcrum"
        title="Solutioning In Software Development"
        desc="Explore our blog for the latest insights on custom software development, product design, and data engineering. Stay ahead with expert tips and industry trends"
      />
      <Blog />
    </Layout>
  )
}
